import {
  EditorReadyFn,
  EditorScriptFlowAPI,
  FlowEditorSDK,
  TFunction,
} from '@wix/yoshi-flow-editor';

import * as publicApi from './editor/services/public-api';
import { registerGfppEventListeners } from './editor/services/gfpp-event-listener';
import { createAppManifest } from './components/MyAccount/manifest';
import { Experiment } from './types';

let editorSDK: FlowEditorSDK;
let flowAPI: EditorScriptFlowAPI;
let appDefId: string;

const editorReady: EditorReadyFn = async (
  _editorSDK,
  appDefinitionId,
  _,
  _flowAPI,
) => {
  editorSDK = _editorSDK;
  flowAPI = _flowAPI;
  appDefId = appDefinitionId;

  registerGfppEventListeners(editorSDK);
};

const getAppManifest = () => {
  const isCustomProfileEnabled = flowAPI.experiments.enabled(
    Experiment.CustomProfile,
  );

  return isCustomProfileEnabled
    ? createAppManifest(flowAPI.translations.t as TFunction)
    : {};
};

const _exports = {
  refreshApp: () => publicApi.refreshApp(editorSDK),
  setBooleanStyleParamForWidgets: (key: string, value: boolean) => {
    return publicApi.setBooleanStyleParamForWidgets(
      editorSDK,
      appDefId,
      key,
      value,
    );
  },
};

export { editorReady, getAppManifest, _exports as exports };
